<template>
	<div class="notice">
		<div style="display: flex;justify-content: space-between;">
			<div class="header">
				<div>赛事管理></div>
				<div class="current">赛事报名</div>
			</div>
			<div class="input">
				<el-input class="input-with-select" placeholder="请输入赛事" style="font-size: 15PX;" v-model="info.matchName">
					<el-button slot="append" style="font-size: 17PX;" @click="searchfor">搜索</el-button>
				</el-input>
			</div>
		</div>
		<div class="game">
			<p>北京体育赛事报名</p>
			<img src="../assets/image/game2.png" alt="">
		</div>

		<div class="column">
			<div class="name" @click="column(0)"
				:style="{'background':columnIndex == 0 ? '#164B92':'#ECF1FD','color':columnIndex == 0 ? '#FFFFFF':'#3C75C2' }">
				所有
			</div>
			<div class="name" @click="column(2)"
				:style="{'background':columnIndex == 2 ? '#164B92':'#ECF1FD','color':columnIndex == 2 ? '#FFFFFF':'#3C75C2' }">
				报名中
			</div>
			<div class="name" @click="column(1)"
				:style="{'background':columnIndex == 1 ? '#164B92':'#ECF1FD','color':columnIndex == 1 ? '#FFFFFF':'#3C75C2' }">
				报名未开始
			</div>
			<div class="name" @click="column(3)"
				:style="{'background':columnIndex == 3 ? '#164B92':'#ECF1FD','color':columnIndex == 3 ? '#FFFFFF':'#3C75C2' }">
				报名已结束
			</div>
		</div>

		<div class="course-box">
			<div class="course" v-for="(item,index) in data" :key="index" @click="getdetails(item)">
				<div class="introduction">
					<img class="img" :src="item.image" alt="">
				</div>
				<div class="name-container">
					<div class="name">{{item.matchName}}</div>
				</div>
				<div class="line" style="margin: 0;"></div>
				<div class="specific-box">
					<div class="specific">
						<img class="img" v-if="item.status == 2" src="../assets/image/date2.png" alt="">
						<img class="img" v-else src="../assets/image/date1.png" alt="">
						<div class="information">
							<div class="information-title">报名时间</div>
							<div class="information-date">{{item.registerDateStart | format}} 至 {{item.registerDateEnd | format}}</div>
						</div>
					</div>
					<div class="line"></div>
					<div class="specific">
						<img class="img" v-if="item.status == 2" src="../assets/image/addes2.png" alt="">
						<img class="img" v-else src="../assets/image/addes1.png" alt="">
						<div class="information">
							<div class="information-title">比赛地点</div>
							<div class="information-date">{{item.matchAddressStadium }}</div>
						</div>
					</div>
					<div class="button" :style="{'background':item.status == 2 ? '#0A6DF3':'#C8D5E7'}">
						{{ item.status == 2 ? '报名中':(item.status == 3 ? '已结束':'报名未开始') }}
					</div>
				</div>
			</div>
		</div>

		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="12" :pageSize="12" :pageNo="info.pageNo"></pagination>
		</div>

	</div>
</template>
<script>
	export default {
		name: 'notice',
		data() {
			return {
				columnIndex: 0,
				start: true,
				total: 0,
				info: {
					isOnlineRegister: 0,
					pageNo: 1,
					pageSize: 12,
					status:0,
					matchName:''
				},
				data: [],
				columnId: '',
				columnPid: '',
			}
		},
		created() {
			this.columnId = this.$route.query.id
			this.columnPid = this.$route.query.pid
			this.getMatchBasicInfo()
		},
		filters: {
			format(x) {
				if(x == '' || x == null || x == undefined){
					return ''
				}else{
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			currentchange(val) {
				this.info.pageNo = val
				this.getMatchBasicInfo()
			},
			column(e) {
				this.columnIndex = e
				this.info.status = e
				this.getMatchBasicInfo()
			},
			searchfor(){
				this.info.pageNo = 1
				this.getMatchBasicInfo()
			},
			getMatchBasicInfo() {
				this.$api.getMatchBasicInfo(this.info).then(res => {
					res.data.data.result.records.forEach(item=>{
						item.matchAddressStadium = item.addresses.map(item=>{
							return item.matchAddressStadium
						}).toString()
						this.$api.getFile({objectName:item.imgs[0]}).then(res=>{
							this.$convert(res.data.data.result.url).then((fileUrl) => {
							  item.image = fileUrl
							  this.$forceUpdate()
							})
						})
					})
					this.data = res.data.data.result.records
					this.total = res.data.data.result.total
				})
			},
			getdetails(row) {
				this.$router.push({
					path: '/application',
					query: {
						id: row.id,
						refresh: new Date().getTime(),
						type: 1,
						columnId: this.columnId,
						columnPid: this.columnPid
					}
				});
			}
		}
	}
</script>
<style  lang="scss" scoped>
	.notice {
		width: 1200PX;
		margin: 0 auto;
	}

	.header {
		display: flex;
		margin-top: 34PX;
		margin-left: 20PX;
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		cursor: pointer;
	}

	.current {
		color: #0066FF;
	}

	.input {
		margin-top: 28PX;
		height: 39PX;
	}

	.input ::v-deep .el-input__inner {
		height: 34PX;
		border: 2PX solid #164B92;
		height: 39PX;
		width: 160PX;
	}

	.input ::v-deep .el-input-group__append {
		border: 2PX solid #164B92;
		color: #fff;
		background-color: #164B92;
		height: 39PX;
	}

	.input ::v-deep .el-input-group__append button.el-button {
		font-weight: bold;
	}

	.game {
		margin-top: 22PX;
	}

	.game p {
		position: absolute;
		margin-left: 163PX;
		font-size: 30PX;
		font-weight: 500;
		color: #164B92;
		margin-top: 38PX;
	}

	.column {
		margin-top: 46PX;
		margin-bottom: 39PX;
		display: flex;
		justify-content: space-between;
		border-bottom: 2PX solid #164B92;
		cursor: pointer;
	}

	.column .name {
		width: 268PX;
		height: 61PX;
		background: #164B92;
		border-radius: 20PX 20PX 0PX 0PX;
		font-size: 20PX;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 36PX;
		text-align: center;
		line-height: 61PX;
	}

	.course-box {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.course {
		width: 266PX;
		height: 389PX;
		border-radius: 17PX;
		margin-bottom: 90PX;
		margin-right: 45PX;
		cursor: pointer;
	}

	.course:nth-child(4n) {
		margin-right: 0;
	}

	.introduction {
		cursor: pointer;
	}

	.introduction img {
		width: 266PX;
		height: 191PX;
		border-radius: 17PX 17PX 0PX 0PX;

	}

	.name-container {
		width: 266PX;
		height: 54PX;
		background: rgba(236, 241, 253, 0.65);
		font-size: 16PX;
		font-weight: bold;
		color: #164B92;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
	
	.name-container .name {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		padding: 0 10PX;
	}

	.specific-box {
		width: 266PX;
		height: 198PX;
		background: rgba(236, 241, 253, 0.65);
		border-radius: 0PX 0PX 17PX 17PX;
		cursor: pointer;
	}

	.specific-box .specific {
		display: flex;
	}

	.specific-box .specific .img {
		width: 38PX;
		height: 38PX;
		margin-top: 16PX;
		margin-left: 26PX;
	}

	.specific-box .specific .information {
		margin-left: 11PX;
	}

	.specific-box .specific .information .information-title {
		font-size: 15PX;
		font-weight: bold;
		color: #164B92;
		margin-top: 16PX;
	}

	.specific-box .specific .information .information-date {
		width: 175PX;
		font-size: 13PX;
		font-weight: 500;
		color: #333333;
		margin-top: 8PX;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.specific-box .button {
		width: 181PX;
		height: 36PX;
		background: #0A6DF3;
		border-radius: 18PX;
		font-size: 15PX;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 36PX;
		text-align: center;
		margin-left: 42PX;
		margin-top: 15PX;
	}

	.line {
		width: 262PX;
		height: 1PX;
		background: #DADADB;
		opacity: 0.63;
		margin-top: 7PX;
	}

	.paging {
		margin-top: 27PX;
		margin-bottom: 104PX;
		display: flex;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}
</style>